<script>
	import { page } from '$app/stores';
	import { user } from '../stores.js';
	import { onMount } from 'svelte';
	import logo from '$lib/images/Latest Icons/Heatificate.png';
    import { goto } from '$app/navigation';
	
	onMount(async () => {
		
		const response  = await fetch('api/auth', {
			method: 'GET'
		});
		
	    let data = await response.json();
		data = data[0];
		if(data)
		{
		if(data.email)
		{
		console.log(`email = ${data.email}`);
		user.update(() => {
			return {loggedin: true, email: data.email};
		});
		}
		}
		

	});
	  
	function goToLogin() {
		goto('/login');
	}
	function goToProfile() {
		goto('/profile');
	}

</script>

<header class="header">
	<div class="header-content">
		<img src={logo} alt="Heat-ificate" class="logo">
		<h1 class="title">Heat-ificate</h1>
		<p class="subtitle">by Impalance</p>
		<!-- Horizontal line below header -->
		<div class="fading-line"></div>


		<!-- Centered navigation bar -->
		<nav class="nav-bar">
			<ul>
				<li aria-current={$page.url.pathname === '/' ? 'page' : undefined}>
					<a href="/">Home</a>
				</li>
				<li aria-current={$page.url.pathname === '/purchase' ? 'page' : undefined}>
					<a href="/purchase">Purchase A Licence</a>
				</li>
				<li aria-current={$page.url.pathname === '/contact' ? 'page' : undefined}>
					<a href="/contact">Contact</a>
				</li>
				<li aria-current={$page.url.pathname === '/faq' ? 'page' : undefined}>
					<a href="/faq">FAQ</a>
				</li>
				<li aria-current={$page.url.pathname === '/feedback' ? 'page' : undefined}>
					<a href="/feedback">Feedback</a>
				</li>
			</ul>
		</nav>
	</div>

	{#if !$user.loggedin}	  
	<button class="login-button" on:click={goToLogin}>Register / Login</button>	
	{:else}
	<button class="login-button" on:click={goToProfile}>My Account</button>	
	{/if}
</header>

<style>
	@font-face {
		font-family: 'VoxRound';
		src: url('fonts/VoxRound-Semibold.woff2') format('woff2');
		font-weight: normal;
		font-style: normal;
	}
		/* Basic reset for margin and padding */
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	.header {
		display: grid;
		grid-template-columns: 1fr auto; /* Two columns: one for the content, one for the login button */
		grid-template-rows: auto auto; 
		align-items: center;
		position: relative;
		margin-bottom: 20px;
	}

	.header-content {
		text-align: center;
		width: 100%; /* Ensures central alignment */
		grid-column: span 2;
		grid-row: span 2;
		padding: 0 10%;
	}

	.logo {
		display: block;
		margin: 0 auto; /* Centers the logo */
		max-width: 60px; /* Adjust logo size as needed */
	}

	.title {
		font-family: 'VoxRound';
		font-size: 45px;
		margin-top: 10px;
	}

	.subtitle {
		font-size: 24px;
		margin-top: 5px;
	}

	.login-button {
		padding: 8px 12px;
		font-size: 16px;
		background-color: #007bff;
		color: #fff;
		border: none;
		border-radius: 4px;
		cursor: pointer;
		margin-top: 8px;
		margin-right: 12px;
		grid-column: 2;
		grid-row: 1;
	}

	.login-button:hover {
		background-color: #0056b3;
	}

	/* Horizontal line styling */
	.fading-line {
		width: 90%;
		height: 20px;
		margin: 20px auto;
		background: 
		linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.4) 50%, rgba(0, 0, 0, 0.45) 51%, transparent);
		border-radius: 100px;
	}


	/* Centered navigation bar */
	.nav-bar ul {
		display: flex;
		justify-content: center; /* Centers the nav items */
		gap: 20px; /* Spacing between links */
		list-style-type: none; /* Remove bullet points */
		padding: 0;
		margin: 0;
	}

	.nav-bar a {
		text-decoration: none; /* Remove underline */
		color: #333; /* Link color */
		font-size: 18px;
	}

	.nav-bar a:hover {
		color: #007bff; /* Change color on hover */
	}
	@media (max-width: 600px) {
        .nav-bar ul {
            gap: 10px; /* Reduce space between tabs */
        }
		.nav-bar a {
			font-size: 16px;
		}
    }

</style>
