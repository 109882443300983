
<script>
console.log(import.meta.env.VITE_DOMAIN);
import AppStoreLogo from '$lib/images/AppStore-Image.png';
import GooglePlayLogo from '$lib/images/GooglePlay-Image.png';

if(typeof window !== 'undefined' && window)
    {
        const googleAnalyticsId = 'G-40ST12GVQY'
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        
        // @ts-ignore
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', googleAnalyticsId);
    }

</script>

<svelte:head>
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-40ST12GVQY"></script>
	<title>Heat-ificate</title>
	<meta name="description" content="Heat-ificate" />
</svelte:head>

<section class="box1">
	<div class="welcome-text">
		<h1>
			WELCOME
		</h1>

		<h2>
			Welcome to Heat-ificate! the certification app for engineers created by a gas engineer!

			<br>This all started in 2022, I had been using a multitude of different apps to generate certificates for my landlords and customers gas appliances. However I found they each had their own issues and missing features between them. So I decided to teach myself computer coding and build an app with all the features that where missing and all the issues fixed.
		</h2>
	</div>

    <div class="center-container">
        <div class="secondary-text">
            <h2>Features Include:</h2>
            <ul>
                <li>Automatic uploading to a connected cloud service (Dropbox, Google Drive or Onedrive).</li>
                <li> Uploaded files are automatically sorted into the respective client and certificate folder.</li>
                <li>Save time by creating a new certificate from a template that has all the previous details.</li>
                <li> Certificates are saved into separate folders of your choosing.</li>
                <li> Import a CSV file of your clients and installation addresses to save writing each one out.</li>
                <li>Save a backup of the entire app locally or to a connected cloud service to transfer data between devices.</li>
                <li>Dropdown lists e.g Locations that are customisable and save a top 5 of your recently selected.</li>
                <li>  Automatic notification alert for due certificates.</li>
            </ul>
        </div>
        <div class="button-container">
            <a href="https://play.google.com/store/apps/details?id=com.Impalance.Heatificate" class="store-button" target="_blank">
                <img src={GooglePlayLogo} alt="Get on Play Store" class="googlestore-logo" />
            </a>
            <a href="https://apps.apple.com/gb/app/heat-ificate/id6642682921" class="store-button" target="_blank">
                <img src={AppStoreLogo} alt="Get on Apple Store" class="appstore-logo" />
            </a>
        </div>
    </div>
</section>

<section class="box2">
	<div class="card">
		<h3 class="card-title">Employers Licence</h3>
		<p class="card-price">TBA</p>
		<p class="card-period">Every year</p>
		<ul class="licence-details">
		  <li>30 day free trial</li>
		</ul>
		<button class="card-button">Start Free Trial</button>
	  </div>
	<a href="mailto:info@impalance.co.uk">info@impalance.co.uk</a>
	
</section>

<style>
	h1 {
		width: 100%;
	}

	.welcome-text{
		padding: 0px 5%;
		color: white;
		text-align: center;
	}

    /* Parent container for centering */
    .center-container {
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically, optional */
        height: 100%; /* Adjust as needed for vertical centering */
        padding: 20px;
        flex-direction: column; /* Stack items vertically */
    }

	.secondary-text{
		color: white;
		padding: 0px 10px;
	}

    .box1 {
        background-color: rgb(131, 131, 131);
        padding: 0px 5%;
    }

    .box2 {
        background-color: rgb(255, 255, 255);
        padding-bottom: 20px;
        text-align: center;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		flex: 0.6;
    }

    /* Basic card style */
    .card {
        border: 2px solid rgb(122, 122, 122);
        border-radius: 40px;
        padding: 40px;
		width: 25%;
		min-width: 250px;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
        text-align: center;
        background-color: #fff;
        margin: 20px;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    .card:hover {
        transform: translateY(-5px);
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    }

    /* Title style */
    .card-title {
        font-size: 24px;
        font-weight: bold;
        margin: 0px;
    }

    /* Price style */
    .card-price {
        font-size: 50px;
        font-weight: bold;
        color: #007bff;
        margin: 0px;
    }

    /* Period style */
    .card-period {
        font-size: 16px;
        color: #777;
        margin: 0px;
    }

    /* List of details */
    .licence-details {
        list-style-type: none;
        padding: 0;
        margin: 0;
        margin: 20px;
        text-align: center;
    }

    /* Button style */
    .card-button {
        width: 90%;
        height: 50px;
        background-color: #007bff;
        color: white;
        margin: 10px 0px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .card-button:hover {
        background-color: #0056b3;
    }

    .button-container {
    display: flex;
    justify-content: center; /* Align buttons horizontally */
    gap: 20px; /* Space between buttons */
    margin-top: 20px; /* Add some space above the buttons */
}

.store-button {
    display: inline-block;
    text-align: center;
}

.googlestore-logo {
    max-width: 170px; /* Adjust as needed for logo size */
    height: auto; /* Maintain aspect ratio */
    transition: transform 0.3s; /* Add hover animation */
}
.appstore-logo {
    max-width: 150px; /* Adjust as needed for logo size */
    height: auto; /* Maintain aspect ratio */
    transition: transform 0.3s; /* Add hover animation */
}

.appstore-logo:hover {
    transform: scale(1.1); /* Slightly enlarge on hover */
}
.googlestore-logo:hover {
    transform: scale(1.1); /* Slightly enlarge on hover */
}


</style>